html,
body {
  margin: 0;
  padding: 0;
}

.app {
  background-color: var(--color-blue--light);
  min-height: 100vh;
}
