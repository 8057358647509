.logo {
  font-weight: 700;
  border-right: 1px solid var(--color-gray--light);
  padding: var(--space-5) var(--space-6);

  @media (max-width: 400px) {
    padding: var(--space-5) var(--space-5);
  }

  @media (max-width: 375px) {
    display: none;
  }
}

.title {
  padding: var(--space-5) var(--space-6);
  color: var(--color-blue-gray);

  @media (max-width: 400px) {
    padding: var(--space-5) var(--space-5);
  }
}

.button {
  margin: 0 var(--space-4);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner {
  display: flex;
  align-items: center;
  background: #f0e889;
  padding: 1rem 2rem;
  color: #3c3a0c;
}
